import React from "react";

import "./style/Header.css";
import { Link } from "react-router-dom";

function funcao() {
    if (
        document.querySelector('.navrespoim') &&
        document.querySelector('.botaoresp') &&
        document.querySelector('.fechar')
    ) {
        const objnav = document.querySelector('.navrespoim');
        const obj = document.querySelector('.botaoresp');
        obj.style.height = "50vh"
        obj.style.visibility = "visible"
        document.querySelector('.fechar').addEventListener("click", () => {
            obj.style.display = 'none'; 
        })
      
        obj.style.display = 'block';
      
        
    }
}

const Header = () => {
 
    return(
        <div className="headd" id="headd">
            <div className="botaoresp">
                <p className="fechar">X</p>
            <li><a href="#headd" id="">página inicial</a></li>
                  
                    <li><a href="/studios" id="">STUDIOS</a></li>
                 
                    <li><a href="#contato" id="">CONTATO</a></li>
            </div>
            <nav className="nav-bar-mor" id="home">
                <a onClick={funcao}className="navrespo"><img  className="navrespoim"src="./menu.png"></img></a>
                <ul className="nav-bar">
                    <li><a href="#headd" id="style-1">Início</a></li>
                    |
                    <li><a href="/studios" id="style-1">STUDIOS</a></li>
                    |
                    <li><a href="#contato" id="style-1">CONTATO</a></li>
                </ul>
                <div className="progress-bar-mor">
                <div className="container">
                            <p>estágio da obra: 7%</p>
                            <div className="progress-bar"></div>
                        </div>
                </div>
            </nav>
            <div className="principal-header" id="pheader">
                <img src="./elos.png" className="logo_elos" data-aos="fade-right" data-aos-duration="1000"/>
                <div data-aos="fade-right" data-aos-duration="1000">
                    <p  className="metr"data-aos="fade-right" data-aos-duration="1000">Studios a partir de R$262.000,00 com metragens até 56,74m²</p>
                </div>
            </div>
            <div className="float">
                <i className="fa fa-plus my-float"></i> 
                <a href="https://api.whatsapp.com/send?phone=5541996480900" class="float" ><img class="whats"src="whatsapp.png"/></a>
                <a href="https://www.facebook.com/concrezatto/" ><img class="face"src="facebook.png"/> </a>
                <a href="https://www.instagram.com/concrezatto_construtora/?igshid=OTJlNzQ0NWM%3D"><img class="insta"src="instagram.png"/> </a>
            </div>
        </div>
    )
}

export default Header;